.btn-outline-primary,
.btn-primary {
    box-shadow: 0 2px 2px rgba(116, 96, 238, 0.05);
}

.btn-outline-primary:hover,
.btn-primary:hover {
    box-shadow: 0 8px 15px rgba(116, 96, 238, 0.3);
}

.btn-outline-secondary,
.btn-secondary {
    box-shadow: 0 2px 2px rgba(134, 142, 150, 0.05);
}

.btn-outline-secondary:hover,
.btn-secondary:hover {
    box-shadow: 0 8px 15px rgba(134, 142, 150, 0.3);
}

.btn-outline-success,
.btn-success {
    box-shadow: 0 2px 2px rgba(57, 196, 73, 0.05);
}

.btn-outline-success:hover,
.btn-success:hover {
    box-shadow: 0 8px 15px rgba(57, 196, 73, 0.3);
}

.btn-info,
.btn-outline-info {
    box-shadow: 0 2px 2px rgba(0, 158, 251, 0.05);
}

.btn-info:hover,
.btn-outline-info:hover {
    box-shadow: 0 8px 15px rgba(0, 158, 251, 0.3);
}

.btn-outline-warning,
.btn-warning {
    box-shadow: 0 2px 2px rgba(255, 188, 52, 0.05);
}

.btn-outline-warning:hover,
.btn-warning:hover {
    box-shadow: 0 8px 15px rgba(255, 188, 52, 0.3);
}

.btn-danger,
.btn-outline-danger {
    box-shadow: 0 2px 2px rgba(246, 45, 81, 0.05);
}

.btn-danger:hover,
.btn-outline-danger:hover {
    box-shadow: 0 8px 15px rgba(246, 45, 81, 0.3);
}

.btn-light,
.btn-outline-light {
    box-shadow: 0 2px 2px rgba(235, 243, 245, 0.05);
}

.btn-light:hover,
.btn-outline-light:hover {
    box-shadow: 0 8px 15px rgba(235, 243, 245, 0.3);
}

.btn-dark,
.btn-outline-dark {
    box-shadow: 0 2px 2px rgba(38, 50, 56, 0.05);
}

.btn-dark:hover,
.btn-outline-dark:hover {
    box-shadow: 0 8px 15px rgba(38, 50, 56, 0.3);
}

.btn-cyan,
.btn-outline-cyan {
    box-shadow: 0 2px 2px rgba(79, 195, 247, 0.05);
}

.btn-cyan:hover,
.btn-outline-cyan:hover {
    box-shadow: 0 8px 15px rgba(79, 195, 247, 0.3);
}

.btn-orange,
.btn-outline-orange {
    box-shadow: 0 2px 2px rgba(251, 140, 0, 0.05);
}

.btn-orange:hover,
.btn-outline-orange:hover {
    box-shadow: 0 8px 15px rgba(251, 140, 0, 0.3);
}

.btn-outline-purple,
.btn-purple {
    box-shadow: 0 2px 2px rgba(116, 96, 238, 0.05);
}

.btn-outline-purple:hover,
.btn-purple:hover {
    box-shadow: 0 8px 15px rgba(116, 96, 238, 0.3);
}

.btn-inverse,
.btn-outline-inverse {
    box-shadow: 0 2px 2px rgba(47, 61, 74, 0.05);
}

.btn-inverse:hover,
.btn-outline-inverse:hover {
    box-shadow: 0 8px 15px rgba(47, 61, 74, 0.3);
}

.btn-megna,
.btn-outline-megna {
    box-shadow: 0 2px 2px rgba(1, 192, 200, 0.05);
}

.btn-megna:hover,
.btn-outline-megna:hover {
    box-shadow: 0 8px 15px rgba(1, 192, 200, 0.3);
}

.btn-light-danger,
.btn-outline-light-danger {
    box-shadow: 0 2px 2px rgba(249, 231, 235, 0.05);
}

.btn-light-danger:hover,
.btn-outline-light-danger:hover {
    box-shadow: 0 8px 15px rgba(249, 231, 235, 0.3);
}

.btn-light-success,
.btn-outline-light-success {
    box-shadow: 0 2px 2px rgba(232, 253, 235, 0.05);
}

.btn-light-success:hover,
.btn-outline-light-success:hover {
    box-shadow: 0 8px 15px rgba(232, 253, 235, 0.3);
}

.btn-light-warning,
.btn-outline-light-warning {
    box-shadow: 0 2px 2px rgba(255, 248, 236, 0.05);
}

.btn-light-warning:hover,
.btn-outline-light-warning:hover {
    box-shadow: 0 8px 15px rgba(255, 248, 236, 0.3);
}

.btn-light-primary,
.btn-outline-light-primary {
    box-shadow: 0 2px 2px rgba(241, 239, 253, 0.05);
}

.btn-light-primary:hover,
.btn-outline-light-primary:hover {
    box-shadow: 0 8px 15px rgba(241, 239, 253, 0.3);
}

.btn-light-info,
.btn-outline-light-info {
    box-shadow: 0 2px 2px rgba(207, 236, 254, 0.05);
}

.btn-light-info:hover,
.btn-outline-light-info:hover {
    box-shadow: 0 8px 15px rgba(207, 236, 254, 0.3);
}

.btn-light-inverse,
.btn-outline-light-inverse {
    box-shadow: 0 2px 2px rgba(246, 246, 246, 0.05);
}

.btn-light-inverse:hover,
.btn-outline-light-inverse:hover {
    box-shadow: 0 8px 15px rgba(246, 246, 246, 0.3);
}

.btn-light-megna,
.btn-outline-light-megna {
    box-shadow: 0 2px 2px rgba(224, 242, 244, 0.05);
}

.btn-light-megna:hover,
.btn-outline-light-megna:hover {
    box-shadow: 0 8px 15px rgba(224, 242, 244, 0.3);
}

.btn-dark-danger,
.btn-outline-dark-danger {
    box-shadow: 0 2px 2px rgba(230, 41, 75, 0.05);
}

.btn-dark-danger:hover,
.btn-outline-dark-danger:hover {
    box-shadow: 0 8px 15px rgba(230, 41, 75, 0.3);
}

.btn-dark-success,
.btn-outline-dark-success {
    box-shadow: 0 2px 2px rgba(74, 182, 87, 0.05);
}

.btn-dark-success:hover,
.btn-outline-dark-success:hover {
    box-shadow: 0 8px 15px rgba(74, 182, 87, 0.3);
}

.btn-dark-warning,
.btn-outline-dark-warning {
    box-shadow: 0 2px 2px rgba(233, 171, 46, 0.05);
}

.btn-dark-warning:hover,
.btn-outline-dark-warning:hover {
    box-shadow: 0 8px 15px rgba(233, 171, 46, 0.3);
}

.btn-dark-primary,
.btn-outline-dark-primary {
    box-shadow: 0 2px 2px rgba(99, 82, 206, 0.05);
}

.btn-dark-primary:hover,
.btn-outline-dark-primary:hover {
    box-shadow: 0 8px 15px rgba(99, 82, 206, 0.3);
}

.btn-dark-info,
.btn-outline-dark-info {
    box-shadow: 0 2px 2px rgba(2, 142, 225, 0.05);
}

.btn-dark-info:hover,
.btn-outline-dark-info:hover {
    box-shadow: 0 8px 15px rgba(2, 142, 225, 0.3);
}

.page-item.active .page-link {
    background-color: #138496 !important;
    border-color: #117a8b !important;
}

.footer {
    font-size: 14px;
    background: #fff;
    border-top: 1px solid #e9ecef;
    padding: 10px 20px;
    color: #b3b3b3;
}

.preloader {
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    z-index: 99999;
    background: #fff;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    position: absolute;
    top: calc(50% - 3.5px);
    left: calc(50% - 3.5px);
}

.lds-ripple .lds-pos {
    position: absolute;
    border: 2px solid $color-primary;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.1, 0.5, 1) infinite;
}

.lds-ripple .lds-pos:nth-child(2) {
    animation-delay: -0.5s;
}

.notify {
    position: relative;
    top: -35px;
    right: -8px;
}

.notify .heartbeat {
    position: absolute;
    top: -20px;
    right: -4px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border: 5px solid #f62d51;
    border-radius: 70px;
    animation: heartbeat 1s ease-out;
    animation-iteration-count: infinite;
}

.notify .point {
    width: 6px;
    height: 6px;
    border-radius: 30px;
    background-color: #f62d51;
    position: absolute;
    right: 6px;
    top: -10px;
}

.dropdown-menu,
.popover {
    z-index: 400;
}

.dropdown-menu .vyberSearch {
    margin: 0 auto;
    width: 90%;
    box-sizing: border-box;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: $color-secondary;
}

#toast-container > div {
    opacity: 1;
    filter: alpha(opacity=100);
}

.dropdown-visible {
    overflow: visible !important;
}

.collapse.in {
    display: block;
    height: auto;
}

.user-profile .profile-img {
    width: 50px;
}

.user-profile .profile-img::before {
    animation: 2.5s blow 0s linear infinite;
    position: absolute;
    content: "";
    width: 50px;
    height: 50px;
    top: 1.5rem;
    border-radius: 50%;
    z-index: 0;
}

.user-profile .profile-text a {
    color: #fff;
}

.user-profile .profile-text a:hover {
    color: #54667a;
}

.user-profile .dropdown-menu {
    left: 0;
    right: 0;
    top: unset !important;
    width: 180px;
    margin: 0 auto;
}

.custom-control-label::after,
.custom-control-label::before {
    top: 0.15rem;
}

.btn-list .btn {
    margin-bottom: 5px;
}

.btn-circle {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 5px;
    line-height: 30px;
}

.btn-circle.btn-sm,
.btn-group-sm > .btn-circle.btn {
    width: 35px;
    height: 35px;
    padding: 8px 10px;
    font-size: 14px;
}

.btn-circle.btn-lg,
.btn-group-lg > .btn-circle.btn {
    width: 50px;
    height: 50px;
    padding: 14px 10px;
    font-size: 18px;
    line-height: 23px;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 14px 15px;
    font-size: 24px;
}

.btn-rounded {
    border-radius: 60px;
    padding: 7px 18px;
}

.btn-rounded.btn-xs {
    padding: 0.25rem 0.5rem;
    font-size: 10px;
}

.btn-group-sm > .btn-rounded.btn,
.btn-rounded.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 12px;
}

.btn-rounded.btn-md {
    padding: 12px 35px;
    font-size: 16px;
}

.btn-group-lg > .btn-rounded.btn,
.btn-rounded.btn-lg {
    padding: 0.75rem 1.5rem;
}

.btn-xs {
    padding: 0.25rem 0.5rem;
    font-size: 10px;
}

.btn .text-active {
    display: none;
}

.btn.active .text-active {
    display: inline-block;
}

.card {
    margin-bottom: 30px;
}

.card .card-subtitle {
    margin-bottom: 10px;
    font-weight: 300;
    color: #90a4ae;
}

.card .card-title {
    margin-bottom: 10px;
    font-weight: 400;
}

.card .card-actions a {
    cursor: pointer;
    padding: 0 5px;
}

.card .card-header .card-title {
    margin-bottom: 0;
}

.card-group {
    margin-bottom: 30px;
}

.card-group .card {
    border-right: 1px solid #e9ecef;
}

.card-hover {
    transition: all 0.25s ease;
}

.card-hover:hover {
    transform: translateY(-4px) scale(1.01);
    box-shadow: 0 14px 24px rgba(62, 57, 107, 0.1);
}

.draggable-cards .card-header {
    cursor: move;
}

.card-moved .card {
    background: $color-primary;
    color: #fff;
}

.custom-range,
[type="search"],
button.close {
    -webkit-appearance: none;
}

.custom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
}

.custom-range::-moz-range-thumb {
    -moz-appearance: none;
}

* {
    outline: 0;
}

body {
    overflow-x: hidden;
    overflow-y: visible !important;
    margin: 0;
    color: #54667a;
    background: #f2f7f8;
    font-family: $font-primary;
    padding-right: 0 !important;
}

html {
    position: relative;
    min-height: 100%;
}

b,
strong {
    font-weight: 500;
}

a {
    color: $color-primary;
    transition: color 0.2s linear;
}

a:focus,
a:hover {
    text-decoration: none;
}

a.link {
    color: #54667a;
}

a.link:focus,
a.link:hover {
    color: $color-primary;
}

ul.list-style-none {
    margin: 0;
    padding: 0;
}

ul.list-style-none li {
    list-style: none;
}

ul.list-style-none li a {
    display: block;
    padding: 8px 0;
    color: #54667a;
    text-decoration: none;
}

ul.list-style-none li a:hover {
    color: $color-primary;
}

ul.list-icons li a {
    color: #54667a;
}

ul.list-icons li a:hover {
    color: $color-primary;
}

ul.list-icons li i {
    font-size: 13px;
}

.custom-select {
    appearance: none;
    background-size: auto;
    background: #fff url("../../assets/images/custom-select.png") no-repeat right 0.75rem center/8px;
}

.no-wrap td,
.no-wrap th {
    white-space: nowrap;
}

.v-middle td,
.v-middle th {
    vertical-align: middle;
}

.grid-structure .grid-container {
    background-color: #ebf3f5;
    margin-bottom: 10px;
    font-size: 0.8rem;
    padding: 10px 20px;
}

form label {
    font-weight: 400;
    margin-bottom: 0;
}

.error .form-control {
    border-color: #f62d51;
}

.error .help-block {
    color: #f62d51;
}

.validate .form-control {
    border-color: #39c449;
}

.validate .help-block {
    color: #39c449;
}

.calendar-border-right {
    border-right: 1px solid #dee2e6 !important;
}

.datepicker .day,
.datepicker .dow {
    padding: 5px 10px;
}

.docs-buttons .btn,
.docs-data .input-group {
    margin-bottom: 5px;
}

.scrollable {
    position: relative;
}

.input-group .alert {
    margin: 10px auto;
}

.form-control-line .form-group {
    overflow: hidden;
}

.form-control-line .form-control {
    border: 0;
    border-radius: 0;
    padding-left: 0;
    border-bottom: 1px solid #e9ecef;
}

.form-control-line .form-control:focus {
    border-bottom: 1px solid $color-primary;
}

.form-control:focus,
.custom-file-input:focus ~ .custom-file-label {
    border-color: #232148 !important;
    box-shadow: 0 0 0.2rem 0.2rem rgba(103, 40, 118, 0.25) !important;
    outline-color: transparent;
    outline-width: 2px;
    outline-style: dotted;
}

.form-control-danger,
.form-control-success,
.form-control-warning {
    padding-right: 2.25rem;
    background-repeat: no-repeat;
    background-position: center right 0.5625rem;
    background-size: 1.125rem 1.125rem;
}

.has-success .col-form-label,
.has-success .custom-control,
.has-success .form-check-label,
.has-success .form-control-feedback,
.has-success .form-control-label {
    color: #39c449;
}

.has-success .form-control-success {
    background-image: url(../../src/assets/images/icon/success.svg);
}

.has-success .form-control {
    border-color: #39c449;
}

.has-warning .col-form-label,
.has-warning .custom-control,
.has-warning .form-check-label,
.has-warning .form-control-feedback,
.has-warning .form-control-label {
    color: #ffbc34;
}

.has-warning .form-control-warning {
    background-image: url(../../src/assets/images/icon/warning.svg);
}

.has-warning .form-control {
    border-color: #ffbc34;
}

.has-danger .col-form-label,
.has-danger .custom-control,
.has-danger .form-check-label,
.has-danger .form-control-feedback,
.has-danger .form-control-label {
    color: #f62d51;
}

.has-danger .form-control-danger {
    background-image: url(../../src/assets/images/icon/danger.svg);
}

.has-danger .form-control {
    border-color: #f62d51;
}

.none-border .modal-footer {
    border-top: none;
}

.modal-footer {
    padding: 0.75rem 0;
}

.dropdown-colorselector > .dropdown-menu > li > .color-btn.selected:after {
    font-family: "Font Awesome 6 Free";
}

.table tfoot th,
.table th,
.table thead th {
    font-weight: 500;
}

.table .name {
    margin: 13px 0 0 -15px;
}

.page-link {
    color: $color-primary;
}

.dropzone,
.note-editor.note-frame {
    border-color: #e9ecef;
}

.note-toolbar {
    z-index: 1;
}

.note-toolbar-wrapper {
    height: 33px !important;
}

.dropzone {
    border-style: dotted;
}

.disabled {
    pointer-events: none;
    cursor: default;
}

.footable .pagination li:first-child a {
    border-radius: 0.25rem 0 0 0.25rem;
    margin-left: 0;
}

.item-info {
    margin-bottom: 15px;
    margin-left: 15px;
}

.item-info p {
    margin-bottom: 10px !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-secondary;
    color: #2c2b2e;
}

.h3,
h3 {
    font-size: 1.3125rem;
}

.h4,
h4 {
    font-size: 1.125rem;
}

.card {
    border-color: transparent;
}

.progress {
    height: 0.25rem;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.dropdown-item {
    cursor: pointer;
}

.table td {
    padding: 8px;
}

.card {
    margin-bottom: 10px;
}

#frm-ukolForm-file {
    border-radius: 5px;
}

.profiletimeline .sl-item {
    margin-top: 8px;
    display: flex;
    margin-bottom: 0px;
}

div.souvisejici div.card-body {
    min-height: 70px;
}

.profiletimeline .sl-item {
    margin-top: 8px;
    display: flex;
    margin-bottom: 0px;
}

div.souvisejici div.card-body {
    min-height: 70px;
}

.bg-success2 {
    background-color: #409040;
}

.schuzka-delete,
.pointer {
    cursor: pointer;
}

.typ {
    margin-right: 0.75rem !important;
}

/*.typ-schuzky {
    margin: -10px;
    height: auto;
}*/

div.flash {
    background: white;
    border: 1px solid #e2c822;
    padding: 1em;
    width: 100%;
    margin: 10px auto;
    margin-top: 0px;
    border-radius: 0.25rem;
}

div.r {
    border: 1px solid red;
    color: red;
}

div.g {
    border: 1px solid #409040;
    color: #409040;
}

input:not([disabled]).no-autocomplete {
    background-color: #fff !important;
}

.form-control:checked ~ .control-label::before {
    background-color: $color-primary-dark !important;
}

.klient-detail-cont .card {
    margin-bottom: 0;
}

.swal2-popup .swal2-styled.swal2-confirm {
    background-color: $color-primary !important;
    border-color: $color-primary !important;
}

.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
    background: $color-secondary !important;
    border-color: $color-secondary !important;
}

.filepond--drop-label label {
    color: #a0a7af;
}

.custom-file-label::after {
    content: "Procházet" !important;
}

.klient-detail-cont .card-body {
    padding: 1rem;
}

.klient-detail-cont .form-group {
    margin-bottom: 0.25rem;
}

.klient-detail-cont .col-6 {
    padding-left: 0;
    padding-right: 0;
}

/* Css plans */

.netteforms-error-msg {
    position: absolute;
    text-align: right;
    bottom: 0;
    margin-bottom: -1.25rem;
    right: 0.5rem;
    font-size: 0.75rem;
}

.input-group .relatived {
    display: flex;
    position: relative;
    flex: 1 1 auto;
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.was-validated select.form-control:invalid,
select.form-control.is-invalid {
    padding-right: 0.75rem !important;
}

select.is-invalid + span.select2 .select2-selection,
input.is-invalid + input.flattime,
input.is-invalid + input.flatdate {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat !important;
    background-position: right calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

select option {
    padding: 0;
}

.file-event-table {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;

    td {
        padding: 0.375rem 0.75rem;
    }

    td:not(:last-child) {
        border-right: 1px solid #ced4da;
    }

    tr:not(:last-child) > td {
        border-bottom: 1px solid #ced4da;
    }
}

.td-w-1 {
    width: 1%;
    white-space: nowrap;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
    box-shadow: none !important;
}

select option,
select option:checked {
    background-color: #fff;
    color: #495057;
}

select option:checked {
    font-weight: 500;
}

.badge {
    font-size: 1rem;
}

.klient-detail-cont .card-body,
.souvisejici-container .card-body {
    padding: 1rem;
}

.no-gutters {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.klient-detail-cont .form-group,
.souvisejici-container .form-group {
    margin-bottom: 0.25rem;
}

.klient-detail-cont .col-6 {
    padding-left: 0;
    padding-right: 0;
}

.middle-label {
    display: block;
}

.bubble-info {
    border-radius: 1rem;
    width: 100%;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    background-color: #e9ecef;
    color: #2c2b2e;
    box-sizing: border-box;
}

.bubble-info p {
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff !important;
}

.form-control-sm {
    margin-left: 0.25rem;
}

.btn > i.fas {
    margin-right: 0.25rem;
}

.message-center i.far {
    width: 16px;
    margin-right: 0.75rem;
}

.number-no-arrows::-webkit-outer-spin-button,
.number-no-arrows::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

.number-no-arrows {
    appearance: textfield;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    width: 2rem;
    height: 2rem;
    background-size: 60% 60%;
}

.carousel-control-prev-icon {
    background-position-x: 40%;
}

.carousel-control-next-icon {
    background-position-x: 60%;
}

.nowrap {
    white-space: nowrap;
}

.cursor-pointer {
    cursor: pointer;
}

/* All Media queries */

@include media-breakpoint-down(lg) {
    .email-table {
        min-width: 500px;
    }
    .calendar-border-right {
        border-right: none !important;
    }
}

@media (min-height: 33.875em) {
    .picker--opened .picker__frame {
        position: absolute;
        top: 30%;
        bottom: auto;
    }
}

@include media-breakpoint-down(sm) {
    footer .col-12,
    footer .col-12 .text-right {
        text-align: center !important;
    }
}

#upload {
    opacity: 0;
}

#upload-label {
    position: absolute;
    top: 25px;
    left: 1rem;
    transform: translateY(-50%);
}

.image-area {
    border: 2px dashed rgba(255, 255, 255, 0.7);
    padding: 0.5rem 1rem;
    position: relative;
}

.image-area::before {
    content: "Uploaded image result";
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
    z-index: 1;
}

.image-area img {
    display: block;
    z-index: 2;
    max-width: 16rem;
    max-height: 10rem;
    position: relative;
    object-fit: cover;
}

.toast-top-center {
    top: 0.5rem;
}

.remove-obchod {
    color: white;
    float: right;
    margin: -16px -20px;
}

.boc-edit-form-avatar img {
    object-fit: cover !important;
}

#treeContainer {
    margin: 1rem 0;
    padding: 0;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
}

#uzivatele td,
#uzivatele1 td {
    vertical-align: middle;
}

.uzivatele-border-arrow {
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 50%;
    border-style: solid;
    border-width: 1px;
}

.uzivatele-border-arrow::after {
    @include fa-icon-solid($fa-var-caret-right);
    position: absolute;
    top: 50%;
    left: 100%;
    color: inherit;
    transform: translate(-50%, -50%);
    vertical-align: middle;
    font-size: 1.25rem;
}

.infina-avatar {
    margin: 0px;
    padding: 0px;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.action-circle {
    background: transparent;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    transition: all 300ms ease;
}
.action-circle.completed {
    background: #35ba67;
    border: 1px solid #2fa65c;
}
.action-circle.large {
    height: 24px;
    width: 24px;
}

.panel .table td:first-child {
    width: 200px;
}

.preloader-no-anim * {
    transition: none !important;
}

.fa-disabled {
    opacity: 0.6;
}

.address-suggest {
    z-index: 9999;
}

/*
.modal .form-control {
    width: 100% !important;
}
*/
