$fa-font-path: "../webfonts";

$font-primary: "Roboto", sans-serif;
$font-secondary: "Rubik", sans-serif;

$color-primary: #253675;
$color-primary-dark: #253675;
$color-secondary: #a64ffa;
$color-secondary-dark: #a64ffa;

$pretty--color-default: #253675;
$pretty--color-primary: #253675;

:root {
    --animate-duration: 500ms;
    --animate-delay: 0.2s;
}
