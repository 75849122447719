.mailbox .chat-scroll {
    height: calc(100vh - 100px) !important;
}

.mailbox .message-center {
    height: 317px;
    overflow: auto;
}

.mailbox .message-center .message-item {
    display: flex;
}

.mailbox .message-center .message-item.active,
.mailbox .message-center .message-item:hover {
    background: #ebf3f5;
}

.mailbox .message-center .message-item .message-title {
    color: #212529;
    font-size: 0.875rem;
}

.mailbox .message-center .message-item .user-img {
    width: 40px;
}

.mailbox .message-center .message-item .user-img .profile-status {
    border: 2px solid #fff;
    height: 10px;
    left: 30px;
    position: absolute;
    top: 1px;
    width: 10px;
}

.mailbox .message-center .message-item .user-img .online {
    background: #39c449;
}

.mailbox .message-center .message-item .user-img .busy {
    background: #f62d51;
}

.mailbox .message-center .message-item .user-img .away {
    background: #ffbc34;
}

.mailbox .message-center .message-item .user-img .offline {
    background: #ffbc34;
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
    .mailbox .message-center {
        height: 110px !important;
    }
}
