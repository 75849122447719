.table-action-btn {
    display: inline-block;
    padding: 0.125rem;
    margin: 0 0.125rem;
    vertical-align: middle;
    user-select: none;
    padding: 0.25rem 0.5rem;
    border-radius: 0.2rem;
    font-size: 1rem;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.table-action-btn {
    i,
    span.fa,
    span.fas {
        width: 1.25em;
        text-align: center;
    }
}
